.masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -1rem; /* gutter size offset */
    width: auto;
}
.masonry-grid_column {
    padding-left: 1rem; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 1rem;
}
